import { isRecordStringString } from './guard';

const cleanQueryParams = (queryParams: Record<string, unknown>) =>
    Object.fromEntries(
        Object.entries(queryParams).filter(([, value]) => value !== undefined && value !== ''),
    );

/**
 *
 * @param url - The url to append the query params to
 * @param queryParams - The query params to append to the url
 * @returns - The url with the query params appended
 */
const appendQueryParams = (url: string, queryParams: unknown): string => {
    const queryString = new URLSearchParams(
        isRecordStringString(queryParams) ? queryParams : {},
    ).toString();

    // If there are query params, append them to the url
    if (isRecordStringString(queryParams) && Object.keys(queryParams).length > 0) {
        return `${url}?${queryString}`;
    }
    // If there are no query params, return the url as is
    return url;
};

export { cleanQueryParams, appendQueryParams };
